/*  CSS RESET
    ==========================================================================
    ==========================================================================
    ==========================================================================
    ==========================================================================
    ========================================================================== */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Wallpoet&family=Bebas+Neue&family=Manrope:wght@200..800&family=Outfit:wght@100..900&display=swap');

:root {
	/* CINZAS */
	--color-white: #ffffff;
	--color-grey-light: #a2a2a2;
	--color-grey-medium: #333333;
	--color-black: #0d0d0d;

	/* DEFAULT HARDWORK */
	--color-hardwork-1: #8b27ff;
	--color-hardwork-2: #c727ee;
	--color-hardwork-3: #ff27de;
	--color-hardwork-4: #7b36fc;
	--color-hardwork-5: #bb5cff;

	--gradient-hardwork: linear-gradient(
		110deg,
		var(--color-hardwork-1) 0%,
		var(--color-hardwork-2) 50%,
		var(--color-hardwork-3) 100%
	);
	--gradient-hardwork2: linear-gradient(
		110deg,
		var(--color-hardwork-3) 0%,
		var(--color-hardwork-2) 30%,
		var(--color-hardwork-1) 100%
	);
	--gradient-hardwork-cm-usp-purple: linear-gradient(
		110deg,
		var(--color-hardwork-4) 0%,
		var(--color-hardwork-5) 100%
	);

	/* DEFAULT RESIDÊNCIA */
	--color-residencia-1: #ff0042;
	--color-residencia-2: #ff2322;
	--color-residencia-3: #ff4800;

	--color-residencia-4: #fd2c78;
	--color-residencia-5: #ff8251;
	--gradient-residencia: linear-gradient(
		110deg,
		var(--color-residencia-1) 0%,
		var(--color-residencia-2) 50%,
		var(--color-residencia-3) 100%
	);
	--gradient-residencia2: linear-gradient(110deg, var(--color-residencia-5) 0%, var(--color-residencia-4) 100%);

    --gradient-residencia-new: linear-gradient(180deg #E11941, #E93740)

	--gradient-residencia2x: linear-gradient(110deg, #ff5f46 0%, #ff357c 100%);
	--gradient-residencia3x: linear-gradient(90deg, #fd2c78 0%, #ff8251 100%);
	--gradient-residencia3: linear-gradient(180deg, #ff5f45 0%, #ff357d 100%);
	--gradient-residencia4: linear-gradient(to right, #ff5f45 0%, #ff357d 100%);
	--gradient-residencia5: linear-gradient(135deg, #ff4e33 0%, #ff1f6d 100%);
	--gradient-residencia6: linear-gradient(135deg, #f77772 0%, #ffbf69 100%);
	--gradient-residencia7: linear-gradient(to right, #b22727 0%, #b22726 100%);
	--gradient-residencia8: linear-gradient(135deg, #ff005d 0%, #d52a8a 50%, #a337b6 100%);
	--gradient-residencia-sprint-default: linear-gradient(135deg, #fd2c78 0%, #ff8251 70%, #ff8251 100%);

	--gradient-revalida1: linear-gradient(180deg, #0091a6 0%, #00a286 50%, #00c26e 100%);
	--gradient-revalida2: linear-gradient(135deg, #0091a6 0%, #00a286 50%, #00c26e 100%);
	--gradient-revalida3: linear-gradient(180deg, #00c26e 0%, #00a286 50%, #0091a6 100%);
	--gradient-revalida4: linear-gradient(180deg, #07a868 0%, #059588 50%, #0383a5 100%);
	--gradient-revalida5: linear-gradient(180deg, #424745 0%, #424746 50%);
	--gradient-revalida6: linear-gradient(180deg, #93a8a0 0%, #93a8a1 50%);
	--gradient-revalida7: linear-gradient(135deg, #07a868 0%, #059588 50%, #0383a5 100%);

	/* DEFAULT REVALIDA */
	--color-revalida-1: #0091a6;
	--color-revalida-2: #00a286;
	--color-revalida-3: #00c26e;

	/* NEW REVALIDA COLORS */
	--new-color-revalida-1: #58e1aa;
	--new-color-revalida-2: #4bd1c7;
	--new-color-revalida-3: #3fc1e3;
	--new-color-revalida-4: #424745;
	--new-color-revalida-5: #93a8a0;

	/* TEP COLORS */
	--tep-color-1: #36cf46;
	--tep-color-2: #2cc8a2;

	--gradient-revalida: linear-gradient(
		110deg,
		var(--color-revalida-1) 0%,
		var(--color-revalida-2) 50%,
		var(--color-revalida-3) 100%
	);
	--gradient-revalida2: linear-gradient(
		110deg,
		var(--color-revalida-3) 0%,
		var(--color-revalida-2) 50%,
		var(--color-revalida-1) 100%
	);
	--gradient-revalida3: linear-gradient(
		110deg,
		var(--new-color-revalida-1) 0%,
		var(--new-color-revalida-2) 50%,
		var(--new-color-revalida-3) 100%
	);

	--gradient-tep: linear-gradient(110deg, var(--tep-color-1) 0%, var(--tep-color-1) 4.12%, var(--tep-color-2) 100%);

	/* DEFAULT CLÍNICA MÉDICA */
	--color-clinica-1: #ae3bff;
	--color-clinica-2: #6415fc;
	--gradient-clinica: linear-gradient(110deg, var(--color-clinica-1) 50%, var(--color-clinica-2) 100%);

	/* DEFAULT HARDWORKQ */
	--color-hardworkq-1: #ff005d;
	--color-hardworkq-2: #9f38b9;
	--gradient-hardworkq: linear-gradient(110deg, var(--color-hardworkq-1) 50%, var(--color-hardwork-2) 100%);

	/* DEFAULT CIRURGIA GERAL */
	--color-cirugia-1: #00b7ff;
	--color-cirugia-2: #00ffbd;
	--color-cirugia-3: #36badc;
	--color-cirugia-4: #5b69e5;
	--gradient-cirugia: linear-gradient(110deg, var(--color-cirugia-1) 50%, var(--color-cirugia-2) 100%);
	--gradient-cirugia2: linear-gradient(135deg, var(--color-cirugia-2) 0%, var(--color-cirugia-1) 100%);
	--gradient-cirugia3: linear-gradient(135deg, var(--color-cirugia-3) 0%, var(--color-cirugia-4) 100%);

	--gradient-gray: linear-gradient(135deg, #656565 0%, #292929 100%);
	--gradient-gray2: linear-gradient(110deg, #616161 0%, #353535 100%);

	/* EXCEÇÕES ALERTAS (Vermelho, Amarelo, Verde) */
	--color-red: #fe2121;
	--color-yellow: #d3c01c;
	--color-green: #20bd54;

	/* TRANSPARÊNCIAS */
	--transparent-primary: rgba(13, 13, 13, 0.8);
	--transparent-secondary: rgba(60, 60, 60, 0.8);

	/* MEDIDAS */
	--gutter: 15px;
	--font-size: 16px;

	/* FONT FAMILIES */
	--font-text: 'Poppins', sans-serif;
	--font-titles: 'Roboto Condensed', sans-serif;
	--font-countdown-timer: 'Wallpoet', sans-serif;
}

html {
	font-family: sans-serif;
	line-height: 1.1;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	margin: 0;
	overflow-x: hidden;
	background: var(--color-black);
	background-image: url('../public/waves.png');
	background-repeat: repeat;
	color: var(--color-grey-light);
	font-family: var(--font-text);
	font-size: var(--font-size);
}
.disable-autofill-bg:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.1) inset !important;
    box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.1) inset !important;
    -webkit-text-fill-color: #fff !important; /* Cor do texto */
}

article,
aside,
details,
div,
figcaption,
figure,
footer,
header,
img,
main,
menu,
nav,
section {
	display: block;
}

h1 {
	font-size: 2em;
	margin: 0;
}

figure {
	margin: 0;
}

pre {
	font-family: monospace, monospace;
	font-size: 1em;
}

a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
	cursor: pointer;
}

a:active,
a:hover {
	outline-width: 0;
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}

b,
strong {
	font-weight: bold;
}

code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

dfn {
	font-style: italic;
}

mark {
	background-color: #ff0;
	color: #000;
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

audio,
video {
	display: inline-block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

img {
	border-style: none;
}

svg:not(:root) {
	overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
	font-family: sans-serif;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
	outline: 1px dotted ButtonText;
}

fieldset {
	border: none;
	margin: 0;
	padding: 0;
}

legend {
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal;
}

progress {
	display: inline-block;
	vertical-align: baseline;
}

textarea {
	overflow: auto;
}

[type='checkbox'],
[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	-webkit-appearance: none;
	outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

summary {
	display: list-item;
}

canvas {
	display: inline-block;
}

template {
	display: none;
}

[hidden] {
	display: none;
}

/*  ADICIONAIS
          ==========================================================================
          ==========================================================================
          ==========================================================================
          ==========================================================================
          ========================================================================== */
html,
body {
	min-height: 100%;
}

* {
	position: relative;
	outline: none;
	outline-width: 0;
}

*,
*::before,
*::after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body * ::-webkit-scrollbar {
	width: 8px;
	height: 1px;
}
body * ::-webkit-scrollbar-track {
	background: #2c28284d;
	border-radius: 8px;
}
body * ::-webkit-scrollbar-thumb {
	background: #15121280;
	border-radius: 8px;
}
body * ::-webkit-scrollbar-thumb:hover {
	background: #15121280;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

ul {
	list-style: none;
	padding: 0;
}

article,
aside,
footer,
header,
nav,
section,
figure,
figcaption,
img,
div,
span,
em,
label,
p,
ul,
ol,
li,
a,
span,
blockquote,
h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

/*  GRADIENTES
        ==========================================================================
        ==========================================================================
        ==========================================================================
        ==========================================================================
        ========================================================================== */
.gradient-text {
	background-color: var(--color-hardwork-2);
	background-image: var(--gradient-hardwork);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	display: table;
}

.gradient-text-revalida {
	background-color: var(--color-revalida-2);
	background-image: var(--gradient-revalida);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	display: table;
}

.gradient-text-tep {
	background-color: var(--tep-color-1);
	background-image: var(--gradient-tep);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	display: table;
}

.gradient-text-hardworkq {
	background-color: var(--color-hardworkq-1);
	background-image: var(--gradient-hardworkq);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	display: table;
}

.gradient-text-enare {
	background-color: var(--gradient-residencia2x);
	background-image: var(--gradient-hardworkq);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	display: table;
}

.gradient-text-trilha-r1-residencia {
	background-color: var(--gradient-residencia3);
	background-image: var(--gradient-residencia3);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	display: table;
}

.gradient-text-enare-2 {
	background-color: var(--gradient-residencia3x);
	background-image: var(--gradient-residencia3x);
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}

.residencia .gradient-text {
	background-color: var(--color-residencia-2);
	background-image: var(--gradient-residencia);
}


.revalida .gradient-text {
	background-color: var(--color-revalida-2);
	background-image: var(--gradient-revalida);
}

.residencia .gradient-text-sprint {
	background-color: var(--color-residencia-4);
	background-image: var(--gradient-residencia-sprint-default);
}

.gradient-text-sprint-default {
	background-color: var(--gradient-residencia-sprint-default);
	background-image: var(--gradient-residencia-sprint-default);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	display: table;
}

.gradient-text-cirurgia-default {
	background-color: var(--color-cirugia-4);
	background-image: var(--color-cirugia-1);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	display: table;
}

.clinica-medica .gradient-text {
	background-image: var(--gradient-clinica);
}

.clinica-medica-usp .gradient-text {
	background-image: var(--gradient-hardwork-cm-usp-purple);
}

.hardworkq .gradient-text {
	background-image: var(--gradient-hardworkq);
}

.cirurgia-geral .gradient-text {
	background-image: var(--gradient-cirugia);
}

.background-residencia {
	background-image: var(--gradient-residencia);
}

.background-purple {
	background-image: var(--gradient-hardwork);
}

.background-central-concursos-vertical-revalida {
	background-image: var(--gradient-revalida1);
}

.background-central-concursos-invert-revalida {
	background-image: var(--gradient-revalida3);
}

.background-ldl-revalida-horizontal {
	background-image: var(--gradient-revalida2);
}

.background-central-concursos-vertical {
	background-image: var(--gradient-residencia3);
}

.background-central-concursos-horizontal {
	background-image: var(--gradient-residencia4);
}

.background-new-ldl-residencia-horizontal {
	background-image: var(--gradient-residencia5);
}

.background-revalida {
	background-image: var(--gradient-revalida);
}

.background-sprint-gray {
	background-color: #ebebeb !important;
}

.background-revalida-ldl {
	background-image: var(--gradient-revalida3);
}

.background-revalida-ldl-strong {
	background-image: var(--gradient-revalida4);
}

.background-revalida-ldl-strong-finished {
	background-image: var(--new-color-revalida-4);
}

.background-revalida-ldl-strong-incoming {
	background-image: var(--gradient-revalida6);
}

.white-background {
	background-color: #e3e3e3 !important;
}

.background-default-hardwork {
	background-image: var(--gradient-hardwork2);
}

.background-gradient-white {
	background-image: var(--gradient-white);
}

.background-gradient-purple {
	background-image: var(--gradient-clinica);
}

.background-gradient-cyan {
	background-image: var(--gradient-cirugia);
}

.background-gradient-cyan2 {
	background-image: var(--gradient-cirugia2);
}

.background-gradient-cg {
	background-image: var(--gradient-cirugia3);
}

.background-gradient-revalida {
	background-image: var(--gradient-revalida);
}

.background-tep {
	background-image: var(--gradient-tep);
}

.background-gradient-residencia-light-shadow {
	background-image: var(--gradient-residencia6);
}

.background-gradient-residencia-light-shadow-two {
	background-image: var(--gradient-residencia5);
}

.background-gradient-residencia-light-shadow-revalida {
	background-image: var(--gradient-revalida7);
}

.background-r-plus {
	background-image: var(--gradient-residencia8);
}

.background-gradient-gray {
	background-image: var(--gradient-gray);
}
.background-gradient-gray2 {
	background-image: var(--gradient-gray2);
}

.background-transparent {
	background-color: transparent !important;
}

/* ||||||||||||||||||||||||||||||||||||||||||||||||||||||||| BOXES */
.box {
	backdrop-filter: blur(6px);
	border-radius: var(--gutter);
	box-shadow: 0px calc(var(--gutter) / 3) calc(var(--gutter) * 2) #000000 !important;
	box-sizing: border-box;
	padding: calc(var(--gutter) * 2);
}

.box-primary {
	background: var(--transparent-primary) !important;
}

.box-secondary {
	background: var(--transparent-secondary);
}

.box-tertiary {
	background: var(--color-white);
}

.rounded-card {
	border-radius: 20px !important;
}

#scroll-2::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	background-color: #808080;
}

#scroll-2::-webkit-scrollbar {
	height: 8px;
	background-color: #808080;
}

#scroll-2::-webkit-scrollbar-thumb {
	border-radius: 10px !important;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
	background-color: #555 !important;
}

/* ||||||||||||||||||||||||||||||||||||||||||||||||||||||||| CAROUSEL */

/* @mixin white-gradient {
        background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }
   
    $animationSpeed: 40s;
   
    // Animation
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-250px * 7));
        }
    }
   
    .slider {
        background: white;
        box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
        height: 100px;
        margin: auto;
        overflow: hidden;
        position: relative;
    }
   
    *,
    *&::before,
    *&::after {
        @include white-gradient;
        content: '';
        height: 100px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }
   
    *&::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }
   
    *&::before {
        left: 0;
        top: 0;
    }
   
    .slide-track {
        animation: scroll $animationSpeed linear infinite;
        display: flex !important;
        min-height: 100px !important;
        width: calc(250px * 14);
    }
   
    .slide {
        height: 100px !important;
        width: 250px !important;
    } */

/* ||||||||||||||||||||||||||||||||||||||||||||||||||||||||| BUTTONS */
.btn {
	display: inline-block;
	font-family: var(--font-text) !important;
	font-weight: 700;
	line-height: 1.5;
	color: var(--color-white);
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	background-color: var(--color-black);
	border: none;
	padding: 0.375rem 1.5rem;
	font-size: 1rem;
	border-radius: 1.75em;
	margin: 0 6px;
	min-height: 40px;
	min-width: 160px;
	box-sizing: border-box;
}

.white-btn {
	display: inline-block;
	font-family: var(--font-text) !important;
	font-weight: 700;
	line-height: 1.5;
	color: var(--color-black);
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	background-color: var(--color-white);
	border: none;
	padding: 0.375rem 1.5rem;
	font-size: 1rem;
	border-radius: 1.75em;
	margin: 0 6px;
	min-height: 40px;
	min-width: 160px;
	box-sizing: border-box;
}

.green-btn {
	display: inline-block;
	font-family: var(--font-text) !important;
	font-weight: 700;
	line-height: 1.5;
	color: var(--color-black);
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	background-color: var(--color-white);
	border: none;
	padding: 0.375rem 1.5rem;
	font-size: 1rem;
	border-radius: 1.75em;
	margin: 0 6px;
	min-height: 40px;
	min-width: 160px;
	box-sizing: border-box;
}

/*PRIMARY + SECONDARY*/
.btn-primary,
.btn-secondary {
	background-image: var(--gradient-hardwork);
}

.btn-secondary-cm-purple {
	background-image: var(--gradient-hardwork-cm-usp-purple);
}.btn-secondary-residencia {
	background-image: var(--gradient-residencia2x);
}

 .btn-secondary-residencia-2 {
	 background-image: var(--gradient-residencia3x);
 }

.white-btn-primary,
.white-btn-secondary {
	background-image: var(--gradient-hardwork);
}

.white-btn-third {
	background-image: var(--gradient-revalida1);
}

.white-btn-error {
	background-image: var(--gradient-residencia7);
}

.btn-secondary {
	background-origin: border-box;
	border: solid 3px transparent !important;
	box-shadow: 0px 1000px 0px var(--color-black) inset;
}

.white-btn-secondary {
	background-origin: border-box;
	border: solid 3px transparent !important;
	box-shadow: 0px 1000px 0px var(--color-white) inset;
}

.btn-primary:before,
.btn-secondary:before {
	display: block;
	content: ' ';
	z-index: -1;
	background-image: var(--gradient-hardwork);
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	top: -2px;
	left: -2px;
	position: absolute;
	border-radius: 1.75em;
	filter: blur(0px);
	transition: filter 0.5s;
}

.white-btn-primary:before,
.white-btn-secondary:before {
	display: block;
	content: ' ';
	z-index: -1;
	background-image: var(--gradient-hardwork);
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	top: -2px;
	left: -2px;
	position: absolute;
	border-radius: 1.75em;
	filter: blur(0px);
	transition: filter 0.5s;
}

.migration-price-card {
	-webkit-box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 1);
	-moz-box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 1) !important;
}

.revalida-live-card {
	-webkit-box-shadow: 0px 0px 30px 0px #00c26e;
	-moz-box-shadow: 0px 0px 30px 0px #00c26e;
	box-shadow: 0px 0px 30px 0px #00c26e;
}

.extensivo-live-card {
	-webkit-box-shadow: 0px 0px 30px 0px #ff005d;
	-moz-box-shadow: 0px 0px 30px 0px #ff005d;
	box-shadow: 0px 0px 30px 0px #ff005d;
}

.cirurgia-price-card {
	-webkit-box-shadow: 0px 0px 30px 0px #0091a6;
	-moz-box-shadow: 0px 0px 30px 0px #0091a6;
	box-shadow: 0px 0px 30px 0px #0091a6;
}

.clinica-price-card {
	-webkit-box-shadow: 0px 0px 30px 0px #8b27ff;
	-moz-box-shadow: 0px 0px 30px 0px #8b27ff;
	box-shadow: 0px 0px 30px 0px #8b27ff;
}

.hardworq-price-card {
	-webkit-box-shadow: 0px 0px 30px 0px #9f38b9;
	-moz-box-shadow: 0px 0px 30px 0px #9f38b9;
	box-shadow: 0px 0px 30px 0px #9f38b9;
}

.btn-primary:hover:before,
.btn-secondary:hover:before {
	filter: blur(6px);
}

.white-btn-primary:hover:before,
.white-btn-secondary:hover:before {
	filter: blur(6px);
}

.residencia .btn-primary,
.residencia .btn-secondary {
	background-image: var(--gradient-residencia);
}

.residencia .btn-secondary-invert-gradient {
	background-image: var(--gradient-residencia2);
}

.residencia .white-btn-primary,
.residencia .white-btn-secondary {
	background-image: var(--gradient-residencia);
}

.residencia .btn-primary:before,
.residencia .btn-secondary:before {
	background-image: var(--gradient-residencia);
}
.residencia .white-btn-primary:before,
.residencia .white-btn-secondary:before {
	background-image: var(--gradient-residencia);
}

.revalida .btn-primary,
.revalida .btn-secondary {
	background-image: var(--gradient-revalida);
}

.revalida .white-btn-primary,
.revalida .white-btn-secondary {
	background-image: var(--gradient-revalida);
}

.revalida .btn-primary:before,
.revalida .btn-secondary:before {
	background-image: var(--gradient-revalida);
}
.revalida .white-btn-primary:before,
.revalida .white-btn-secondary:before {
	background-image: var(--gradient-revalida);
}

.header-btn:hover {
	border: unset !important;
	border-bottom: 2px solid #fff !important;
}

.login-btn:hover {
	background-color: #fff !important;
	color: black;
	width: 82% !important;
	height: 100% !important;
	transition: 0.45s ease-in-out;
}

.register-btn:hover {
	border: 1px solid !important;
}

.section-hwm-background {
	background-image: var(--gradient-residencia2);
	/* , url('./Assets/Img/bgWave2.png') */
}

.section-hwm-background-rosa {
	background-image: var(--gradient-residencia2x);
	/* , url('./Assets/Img/bgWave2.png') */
}

.section-revalida-background {
	background-image: var(--gradient-revalida2);
}

.title-home {
	font-family: var(--font-titles) !important;
}

.title-card-home {
	font-family: var(--font-titles) !important;
}

.roboto-condensed {
	font-family: var(--font-titles) !important;
}

.home-page-text-content {
	font-family: var(--font-text) !important;
}

.text-content-infocard {
	font-family: var(--font-text) !important;
}

.footer-info-texts {
	font-family: var(--font-text) !important;
}

.newsletter-text {
	font-family: var(--font-text) !important;
}

.poppins {
	font-family: var(--font-text) !important;
}

.wallpoet {
	font-family: var(--font-countdown-timer) !important;
}

.opacity-40:hover {
	opacity: 50% !important;
}

.inlineChildren * {
	display: inline !important;
}
.nowrapChildren * {
	white-space: nowrap !important;
}
.section {
	max-width: 1410px;
	margin: 60px auto;
}

.bullet {
	content: '\2022';
}

@media (max-width: 600px) {
	.section {
		padding: 2vh 5% 0;
		margin: 20px auto 0;
	}
}

@media (max-width: 1200px) {
	.section {
		padding: 2vh 5% 0;
		margin: 20px auto 0;
	}
}

@media (max-width: 1535px) {
	html {
		font-size: 85%;
	}
	.fabricio-img {
		width: 200px;
		top: 3em !important;
		right: -10% !important;
	}
}

@media (max-width: 900px) {
	html {
		font-size: 100%;
	}
}
